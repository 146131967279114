import Parse from "parse";

import type { _User } from "./_User";

type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_FileAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description?: string;
  file: Parse.File;
  tenant?: OD3_Tenant;
  user?: _User;
}

export class OD3_Maintenance_File extends Parse.Object<OD3_Maintenance_FileAttributes> {
  static className: string = "OD3_Maintenance_File";

  constructor(data?: Partial<OD3_Maintenance_FileAttributes>) {
    super("OD3_Maintenance_File", data as OD3_Maintenance_FileAttributes);
  }

  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get file(): Parse.File {
    return super.get("file");
  }
  set file(value: Parse.File) {
    super.set("file", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get user(): _User | undefined {
    return super.get("user");
  }
  set user(value: _User | undefined) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_File", OD3_Maintenance_File);
