import { Button, Descriptions, Drawer, DrawerProps, Typography } from "antd";
import React from "react";
import { _User, Kuenne_AkkordEintraege } from "../types";
import { useAkkordEntries } from "../hooks/useAkkordEntries";
import { useMachineParameters } from "../hooks/useMachineParameters";
import { AkkordBerechnungErich } from "../classes/AkkordBerechnungErich";
import { Description } from "@opendash/ui";
import { Icon } from "@opendash/icons";

type AkkordResultDrawerProps = DrawerProps & {
  month?: Date;
  worker?: Parse.User<_User>;
};
type CostTupel = {
  entry: Kuenne_AkkordEintraege;
  cost: AkkordBerechnungErich | null;
  error: string | null;
};

const AkkordResultDrawer = (props: AkkordResultDrawerProps) => {
  const { result: entries, reload } = useAkkordEntries(
    props.month,
    props.worker
  );
  const { result: machParamter } = useMachineParameters();

  const costEntries = entries.map((entry) => {
    const machParam = machParamter.find(
      (cParam) => cParam.maschine.id === entry.maschine?.id
    );
    if (!machParam) {
      return {
        entry: entry,
        error: "Missing Parameters for Machine",
        cost: null,
      };
    }
    let schwierigkeit = -1;
    const sortedSchwierigkeiten = machParam.schwierigkeitsfaktor.sort(
      (a, b) => a.mm - b.mm
    );

    for (let cIdx = 0; cIdx < sortedSchwierigkeiten.length; cIdx++) {
      const cElement = sortedSchwierigkeiten[cIdx];
      if (cIdx === sortedSchwierigkeiten.length - 1) {
        schwierigkeit = sortedSchwierigkeiten[cIdx].schwierigkeit;
        break;
      }
      if (cElement.mm > entry.durchmesser) {
        schwierigkeit =
          sortedSchwierigkeiten[Math.max(0, cIdx - 1)].schwierigkeit;
        break;
      }
    }
    return {
      entry: entry,
      cost: new AkkordBerechnungErich(
        entry.dichte,
        entry.durchmesser,
        entry.geschwindigkeit,
        entry.gewicht,
        entry.zaehlerEnde - entry.zaehlerStart,
        machParam!.wechselzeit,
        schwierigkeit,
        machParam!.basis,
        machParam!.lauflaengenSkalierung
      ),
      error: null,
    };
  }) as CostTupel[];
  //@ts-ignore
  const byWorker = Object.groupBy(costEntries, (entry) => {
    return entry.entry.werker?.id;
  });
  React.useEffect(() => {
    if (props.open) {
      reload();
    }
  }, [props.open]);
  const data = {} as Record<string, Record<string, CostTupel[]>>;
  Object.keys(byWorker).forEach((key) => {
    //@ts-ignore
    data[key] = Object.groupBy(byWorker[key], (entry: CostTupel) => {
      return `${entry.entry.jahr}-${entry.entry.monat}`;
    });
  });
  const tdStyle = {
    padding: "12px 15px",
  };
  return (
    <Drawer
      extra={[
        <Button
          type="link"
          icon={<Icon icon="fa:print" />}
          onClick={() => print()}
        />,
      ]}
      {...props}
    >
      <div id="costOverview">
        {Object.keys(data).map((werkerID) => {
          return Object.keys(data[werkerID]).map((monthKey) => {
            const firsEntry = data[werkerID][monthKey][0];
            return (
              <div>
                <Typography.Text type="secondary">{`Akkord ${firsEntry.entry.werker.getUsername()} ${monthKey}`}</Typography.Text>

                <table
                  style={{
                    borderCollapse: "collapse",
                    margin: "25px 0",
                    fontSize: "0.9em",
                    fontFamily: "sans-serif",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "var(--ant-primary-color)",
                        color: "#ffffff",
                        textAlign: "left",
                      }}
                    >
                      <th style={tdStyle}>Auftrag</th>
                      <th style={tdStyle}>Menge</th>
                      <th style={tdStyle}>Lohn pro Kilo</th>
                      <th style={tdStyle}>Akkordlohn</th>
                    </tr>
                  </thead>
                  {data[werkerID][monthKey].map((entry, index) => {
                    return (
                      <>
                        <tr
                          style={{
                            borderBottom: "1px solid #dddddd",
                          }}
                        >
                          <td style={tdStyle}>
                            {entry?.entry?.auftrag?.AUFNR || "Ohne Auftrag"}
                          </td>
                          <td style={tdStyle}>
                            {entry.cost?.produziertesGewicht.toFixed(2)} Kg{" "}
                            {`${entry.cost?.gewichtProMeter.toFixed(2)} Kg/m`}{" "}
                          </td>
                          <td style={tdStyle}>
                            {entry.cost?.preisJe100Kilo.toFixed(2)} €/Kg
                          </td>
                          <td style={tdStyle}>
                            {entry.cost?.preisGesamt.toFixed(2) ?? entry.error}{" "}
                            €
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <>
                    <tr
                      style={{
                        borderBottom: "1px solid #dddddd",
                        borderTop: "2px solid #dddddd",
                      }}
                    >
                      <td style={tdStyle} colSpan={3}>
                        <b> Akkordlohn gesamt</b>
                      </td>
                      <td style={tdStyle}>
                        <b>
                          {data[werkerID][monthKey]
                            .reduce((acc, entry) => {
                              return acc + (entry.cost?.preisGesamt ?? 0);
                            }, 0)
                            .toFixed(2)}
                          {" €"}
                        </b>
                      </td>
                    </tr>
                  </>
                </table>
              </div>
            );
          });
        })}
      </div>
    </Drawer>
  );
};
function print() {
  const innerHtml = document.getElementById("costOverview")!.innerHTML;
  const mywindow = window.open("", "PRINT", "height=1080,width=1920");

  mywindow!.document.write("<html><head><title>" + document.title + "</title>");
  mywindow!.document.write("</head><body >");
  mywindow!.document.write("<h1>" + document.title + "</h1>");
  mywindow!.document.write(innerHtml);
  mywindow!.document.write("</body></html>");

  mywindow!.document.close(); // necessary for IE >= 10
  mywindow!.focus(); // necessary for IE >= 10*/

  mywindow!.print();
  mywindow!.close();

  return true;
}

export default AkkordResultDrawer;
