import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface OD3_MES_ArticleAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  artnr: string;
  name: string;
  targettime: number;
  tenant?: OD3_Tenant;
}

export class OD3_MES_Article extends Parse.Object<OD3_MES_ArticleAttributes> {
  static className: string = "OD3_MES_Article";

  constructor(data?: Partial<OD3_MES_ArticleAttributes>) {
    super("OD3_MES_Article", data as OD3_MES_ArticleAttributes);
  }

  get artnr(): string {
    return super.get("artnr");
  }
  set artnr(value: string) {
    super.set("artnr", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get targettime(): number {
    return super.get("targettime");
  }
  set targettime(value: number) {
    super.set("targettime", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_MES_Article", OD3_MES_Article);
