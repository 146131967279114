import Parse from "parse";

import type { _User } from "./_User";

type OD3_Maintenance_File = Parse.Object;
type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_Source_FileAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  files: Parse.Relation<OD3_Maintenance_Source_File, OD3_Maintenance_File>;
  source?: OD3_Source;
  tenant?: OD3_Tenant;
  user?: _User;
}

export class OD3_Maintenance_Source_File extends Parse.Object<OD3_Maintenance_Source_FileAttributes> {
  static className: string = "OD3_Maintenance_Source_File";

  constructor(data?: Partial<OD3_Maintenance_Source_FileAttributes>) {
    super("OD3_Maintenance_Source_File", data as OD3_Maintenance_Source_FileAttributes);
  }

  get files(): Parse.Relation<OD3_Maintenance_Source_File, OD3_Maintenance_File> {
    return super.relation("files");
  }
  get source(): OD3_Source | undefined {
    return super.get("source");
  }
  set source(value: OD3_Source | undefined) {
    super.set("source", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get user(): _User | undefined {
    return super.get("user");
  }
  set user(value: _User | undefined) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_Source_File", OD3_Maintenance_Source_File);
