import Parse from "parse";

export interface OD3_MailTemplateAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  disclaimer?: string;
  language: string;
  template: string;
  type: string;
  watermark?: string;
}

export class OD3_MailTemplate extends Parse.Object<OD3_MailTemplateAttributes> {
  static className: string = "OD3_MailTemplate";

  constructor(data?: Partial<OD3_MailTemplateAttributes>) {
    super("OD3_MailTemplate", data as OD3_MailTemplateAttributes);
  }

  get disclaimer(): string | undefined {
    return super.get("disclaimer");
  }
  set disclaimer(value: string | undefined) {
    super.set("disclaimer", value);
  }
  get language(): string {
    return super.get("language");
  }
  set language(value: string) {
    super.set("language", value);
  }
  get template(): string {
    return super.get("template");
  }
  set template(value: string) {
    super.set("template", value);
  }
  get type(): string {
    return super.get("type");
  }
  set type(value: string) {
    super.set("type", value);
  }
  get watermark(): string | undefined {
    return super.get("watermark");
  }
  set watermark(value: string | undefined) {
    super.set("watermark", value);
  }
}

Parse.Object.registerSubclass("OD3_MailTemplate", OD3_MailTemplate);
