import Parse from "parse";

import type { _User } from "./_User";

type OD3_Maintenance_Ticket = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_DailyScheduleAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  duedate?: Date;
  isCompleted: boolean;
  name: string;
  tenant: OD3_Tenant;
  ticketIDs?: any[];
  tickets: Parse.Relation<OD3_Maintenance_DailySchedule, OD3_Maintenance_Ticket>;
  user: _User;
}

export class OD3_Maintenance_DailySchedule extends Parse.Object<OD3_Maintenance_DailyScheduleAttributes> {
  static className: string = "OD3_Maintenance_DailySchedule";

  constructor(data?: Partial<OD3_Maintenance_DailyScheduleAttributes>) {
    super("OD3_Maintenance_DailySchedule", data as OD3_Maintenance_DailyScheduleAttributes);
  }

  get duedate(): Date | undefined {
    return super.get("duedate");
  }
  set duedate(value: Date | undefined) {
    super.set("duedate", value);
  }
  get isCompleted(): boolean {
    return super.get("isCompleted");
  }
  set isCompleted(value: boolean) {
    super.set("isCompleted", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get ticketIDs(): any[] | undefined {
    return super.get("ticketIDs");
  }
  set ticketIDs(value: any[] | undefined) {
    super.set("ticketIDs", value);
  }
  get tickets(): Parse.Relation<OD3_Maintenance_DailySchedule, OD3_Maintenance_Ticket> {
    return super.relation("tickets");
  }
  get user(): _User {
    return super.get("user");
  }
  set user(value: _User) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_DailySchedule", OD3_Maintenance_DailySchedule);
