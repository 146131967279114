import Parse from "parse";

import type { _User } from "./_User";

type OD3_Tenant = Parse.Object;

export interface OD3_ContactAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  city?: string;
  company?: string;
  department?: string;
  description?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  position?: string;
  postal?: string;
  street?: string;
  tenant?: OD3_Tenant;
  user?: _User;
  website?: string;
}

export class OD3_Contact extends Parse.Object<OD3_ContactAttributes> {
  static className: string = "OD3_Contact";

  constructor(data?: Partial<OD3_ContactAttributes>) {
    super("OD3_Contact", data as OD3_ContactAttributes);
  }

  get city(): string | undefined {
    return super.get("city");
  }
  set city(value: string | undefined) {
    super.set("city", value);
  }
  get company(): string | undefined {
    return super.get("company");
  }
  set company(value: string | undefined) {
    super.set("company", value);
  }
  get department(): string | undefined {
    return super.get("department");
  }
  set department(value: string | undefined) {
    super.set("department", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get email(): string | undefined {
    return super.get("email");
  }
  set email(value: string | undefined) {
    super.set("email", value);
  }
  get firstname(): string | undefined {
    return super.get("firstname");
  }
  set firstname(value: string | undefined) {
    super.set("firstname", value);
  }
  get lastname(): string | undefined {
    return super.get("lastname");
  }
  set lastname(value: string | undefined) {
    super.set("lastname", value);
  }
  get phone(): string | undefined {
    return super.get("phone");
  }
  set phone(value: string | undefined) {
    super.set("phone", value);
  }
  get position(): string | undefined {
    return super.get("position");
  }
  set position(value: string | undefined) {
    super.set("position", value);
  }
  get postal(): string | undefined {
    return super.get("postal");
  }
  set postal(value: string | undefined) {
    super.set("postal", value);
  }
  get street(): string | undefined {
    return super.get("street");
  }
  set street(value: string | undefined) {
    super.set("street", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get user(): _User | undefined {
    return super.get("user");
  }
  set user(value: _User | undefined) {
    super.set("user", value);
  }
  get website(): string | undefined {
    return super.get("website");
  }
  set website(value: string | undefined) {
    super.set("website", value);
  }
}

Parse.Object.registerSubclass("OD3_Contact", OD3_Contact);
