import Parse from "parse";

type OD3_Maintenance_Kanban_State = Parse.Object;
type OD3_Maintenance_Ticket = Parse.Object;
type OD3_Maintenance_Ticket_Kanban_State = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_Ticket_Kanban_State_CurrentAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  state?: OD3_Maintenance_Kanban_State;
  tenant?: OD3_Tenant;
  ticket?: OD3_Maintenance_Ticket;
  ticketState?: OD3_Maintenance_Ticket_Kanban_State;
}

export class OD3_Maintenance_Ticket_Kanban_State_Current extends Parse.Object<OD3_Maintenance_Ticket_Kanban_State_CurrentAttributes> {
  static className: string = "OD3_Maintenance_Ticket_Kanban_State_Current";

  constructor(data?: Partial<OD3_Maintenance_Ticket_Kanban_State_CurrentAttributes>) {
    super("OD3_Maintenance_Ticket_Kanban_State_Current", data as OD3_Maintenance_Ticket_Kanban_State_CurrentAttributes);
  }

  get state(): OD3_Maintenance_Kanban_State | undefined {
    return super.get("state");
  }
  set state(value: OD3_Maintenance_Kanban_State | undefined) {
    super.set("state", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get ticket(): OD3_Maintenance_Ticket | undefined {
    return super.get("ticket");
  }
  set ticket(value: OD3_Maintenance_Ticket | undefined) {
    super.set("ticket", value);
  }
  get ticketState(): OD3_Maintenance_Ticket_Kanban_State | undefined {
    return super.get("ticketState");
  }
  set ticketState(value: OD3_Maintenance_Ticket_Kanban_State | undefined) {
    super.set("ticketState", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_Ticket_Kanban_State_Current", OD3_Maintenance_Ticket_Kanban_State_Current);
