import Parse from "parse";

type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_ItemAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  category?: string;
  description?: string;
  internalNumber?: string;
  name: string;
  orderNumber?: string;
  price?: string;
  sources: Parse.Relation<OD3_Maintenance_Item, OD3_Source>;
  stock?: number;
  stockAlarmAt?: number;
  stockUnit?: string;
  storageLocation?: string;
  supplier?: string;
  tenant: OD3_Tenant;
}

export class OD3_Maintenance_Item extends Parse.Object<OD3_Maintenance_ItemAttributes> {
  static className: string = "OD3_Maintenance_Item";

  constructor(data?: Partial<OD3_Maintenance_ItemAttributes>) {
    super("OD3_Maintenance_Item", data as OD3_Maintenance_ItemAttributes);
  }

  get category(): string | undefined {
    return super.get("category");
  }
  set category(value: string | undefined) {
    super.set("category", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get internalNumber(): string | undefined {
    return super.get("internalNumber");
  }
  set internalNumber(value: string | undefined) {
    super.set("internalNumber", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get orderNumber(): string | undefined {
    return super.get("orderNumber");
  }
  set orderNumber(value: string | undefined) {
    super.set("orderNumber", value);
  }
  get price(): string | undefined {
    return super.get("price");
  }
  set price(value: string | undefined) {
    super.set("price", value);
  }
  get sources(): Parse.Relation<OD3_Maintenance_Item, OD3_Source> {
    return super.relation("sources");
  }
  get stock(): number | undefined {
    return super.get("stock");
  }
  set stock(value: number | undefined) {
    super.set("stock", value);
  }
  get stockAlarmAt(): number | undefined {
    return super.get("stockAlarmAt");
  }
  set stockAlarmAt(value: number | undefined) {
    super.set("stockAlarmAt", value);
  }
  get stockUnit(): string | undefined {
    return super.get("stockUnit");
  }
  set stockUnit(value: string | undefined) {
    super.set("stockUnit", value);
  }
  get storageLocation(): string | undefined {
    return super.get("storageLocation");
  }
  set storageLocation(value: string | undefined) {
    super.set("storageLocation", value);
  }
  get supplier(): string | undefined {
    return super.get("supplier");
  }
  set supplier(value: string | undefined) {
    super.set("supplier", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_Item", OD3_Maintenance_Item);
