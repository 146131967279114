import Parse from "parse";

import type { _User } from "./_User";

type Kuenne_QATest = Parse.Object;
type OD3_Source = Parse.Object;

export interface Kuenne_AkkordEintraegeAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  auftrag?: Kuenne_QATest;
  datum?: Date;
  dichte: number;
  durchmesser: number;
  geschwindigkeit: number;
  gewicht: number;
  jahr?: number;
  maschine?: OD3_Source;
  monat: number;
  werker: _User;
  zaehlerEnde: number;
  zaehlerStart: number;
}

export class Kuenne_AkkordEintraege extends Parse.Object<Kuenne_AkkordEintraegeAttributes> {
  static className: string = "Kuenne_AkkordEintraege";

  constructor(data?: Partial<Kuenne_AkkordEintraegeAttributes>) {
    super("Kuenne_AkkordEintraege", data as Kuenne_AkkordEintraegeAttributes);
  }

  get auftrag(): Kuenne_QATest | undefined {
    return super.get("auftrag");
  }
  set auftrag(value: Kuenne_QATest | undefined) {
    super.set("auftrag", value);
  }
  get datum(): Date | undefined {
    return super.get("datum");
  }
  set datum(value: Date | undefined) {
    super.set("datum", value);
  }
  get dichte(): number {
    return super.get("dichte");
  }
  set dichte(value: number) {
    super.set("dichte", value);
  }
  get durchmesser(): number {
    return super.get("durchmesser");
  }
  set durchmesser(value: number) {
    super.set("durchmesser", value);
  }
  get geschwindigkeit(): number {
    return super.get("geschwindigkeit");
  }
  set geschwindigkeit(value: number) {
    super.set("geschwindigkeit", value);
  }
  get gewicht(): number {
    return super.get("gewicht");
  }
  set gewicht(value: number) {
    super.set("gewicht", value);
  }
  get jahr(): number | undefined {
    return super.get("jahr");
  }
  set jahr(value: number | undefined) {
    super.set("jahr", value);
  }
  get maschine(): OD3_Source | undefined {
    return super.get("maschine");
  }
  set maschine(value: OD3_Source | undefined) {
    super.set("maschine", value);
  }
  get monat(): number {
    return super.get("monat");
  }
  set monat(value: number) {
    super.set("monat", value);
  }
  get werker(): _User {
    return super.get("werker");
  }
  set werker(value: _User) {
    super.set("werker", value);
  }
  get zaehlerEnde(): number {
    return super.get("zaehlerEnde");
  }
  set zaehlerEnde(value: number) {
    super.set("zaehlerEnde", value);
  }
  get zaehlerStart(): number {
    return super.get("zaehlerStart");
  }
  set zaehlerStart(value: number) {
    super.set("zaehlerStart", value);
  }
}

Parse.Object.registerSubclass("Kuenne_AkkordEintraege", Kuenne_AkkordEintraege);
