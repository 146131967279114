import Parse from "parse";

type OD3_Contact = Parse.Object;
type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_CompanyAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  contacts: Parse.Relation<OD3_Company, OD3_Contact>;
  name?: string;
  sources: Parse.Relation<OD3_Company, OD3_Source>;
  tenant?: OD3_Tenant;
}

export class OD3_Company extends Parse.Object<OD3_CompanyAttributes> {
  static className: string = "OD3_Company";

  constructor(data?: Partial<OD3_CompanyAttributes>) {
    super("OD3_Company", data as OD3_CompanyAttributes);
  }

  get contacts(): Parse.Relation<OD3_Company, OD3_Contact> {
    return super.relation("contacts");
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get sources(): Parse.Relation<OD3_Company, OD3_Source> {
    return super.relation("sources");
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Company", OD3_Company);
