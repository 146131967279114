import Parse from "parse";

type OD3_Source = Parse.Object;

export interface Kuenne_AkkordMaschinenParameterAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  basis: number;
  lauflaengenSkalierung: number;
  maschine: OD3_Source;
  maschinenzusatz: number;
  schwierigkeitsfaktor: any[];
  wechselzeit: number;
}

export class Kuenne_AkkordMaschinenParameter extends Parse.Object<Kuenne_AkkordMaschinenParameterAttributes> {
  static className: string = "Kuenne_AkkordMaschinenParameter";

  constructor(data?: Partial<Kuenne_AkkordMaschinenParameterAttributes>) {
    super("Kuenne_AkkordMaschinenParameter", data as Kuenne_AkkordMaschinenParameterAttributes);
  }

  get basis(): number {
    return super.get("basis");
  }
  set basis(value: number) {
    super.set("basis", value);
  }
  get lauflaengenSkalierung(): number {
    return super.get("lauflaengenSkalierung");
  }
  set lauflaengenSkalierung(value: number) {
    super.set("lauflaengenSkalierung", value);
  }
  get maschine(): OD3_Source {
    return super.get("maschine");
  }
  set maschine(value: OD3_Source) {
    super.set("maschine", value);
  }
  get maschinenzusatz(): number {
    return super.get("maschinenzusatz");
  }
  set maschinenzusatz(value: number) {
    super.set("maschinenzusatz", value);
  }
  get schwierigkeitsfaktor(): any[] {
    return super.get("schwierigkeitsfaktor");
  }
  set schwierigkeitsfaktor(value: any[]) {
    super.set("schwierigkeitsfaktor", value);
  }
  get wechselzeit(): number {
    return super.get("wechselzeit");
  }
  set wechselzeit(value: number) {
    super.set("wechselzeit", value);
  }
}

Parse.Object.registerSubclass("Kuenne_AkkordMaschinenParameter", Kuenne_AkkordMaschinenParameter);
