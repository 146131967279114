import Parse from "parse";

import type { _Role } from "./_Role";
import type { _User } from "./_User";

type OD3_Maintenance_Ticket = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_Ticket_AssignmentAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  assignedRole?: _Role;
  assignedUser?: _User;
  batchStamp: Date;
  tenant?: OD3_Tenant;
  ticket?: OD3_Maintenance_Ticket;
  user: _User;
}

export class OD3_Maintenance_Ticket_Assignment extends Parse.Object<OD3_Maintenance_Ticket_AssignmentAttributes> {
  static className: string = "OD3_Maintenance_Ticket_Assignment";

  constructor(data?: Partial<OD3_Maintenance_Ticket_AssignmentAttributes>) {
    super("OD3_Maintenance_Ticket_Assignment", data as OD3_Maintenance_Ticket_AssignmentAttributes);
  }

  get assignedRole(): _Role | undefined {
    return super.get("assignedRole");
  }
  set assignedRole(value: _Role | undefined) {
    super.set("assignedRole", value);
  }
  get assignedUser(): _User | undefined {
    return super.get("assignedUser");
  }
  set assignedUser(value: _User | undefined) {
    super.set("assignedUser", value);
  }
  get batchStamp(): Date {
    return super.get("batchStamp");
  }
  set batchStamp(value: Date) {
    super.set("batchStamp", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get ticket(): OD3_Maintenance_Ticket | undefined {
    return super.get("ticket");
  }
  set ticket(value: OD3_Maintenance_Ticket | undefined) {
    super.set("ticket", value);
  }
  get user(): _User {
    return super.get("user");
  }
  set user(value: _User) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_Ticket_Assignment", OD3_Maintenance_Ticket_Assignment);
