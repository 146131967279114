import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface OD3_SourceMetaAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  data?: any;
  description?: string;
  file?: Parse.File;
  kpiData: any[];
  location?: Parse.GeoPoint;
  name?: string;
  tenant?: OD3_Tenant;
}

export class OD3_SourceMeta extends Parse.Object<OD3_SourceMetaAttributes> {
  static className: string = "OD3_SourceMeta";

  constructor(data?: Partial<OD3_SourceMetaAttributes>) {
    super("OD3_SourceMeta", data as OD3_SourceMetaAttributes);
  }

  get data(): any | undefined {
    return super.get("data");
  }
  set data(value: any | undefined) {
    super.set("data", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get file(): Parse.File | undefined {
    return super.get("file");
  }
  set file(value: Parse.File | undefined) {
    super.set("file", value);
  }
  get kpiData(): any[] {
    return super.get("kpiData");
  }
  set kpiData(value: any[]) {
    super.set("kpiData", value);
  }
  get location(): Parse.GeoPoint | undefined {
    return super.get("location");
  }
  set location(value: Parse.GeoPoint | undefined) {
    super.set("location", value);
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_SourceMeta", OD3_SourceMeta);
