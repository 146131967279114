import Parse from "parse";

type OD3_MES_Article = Parse.Object;
type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_MES_OrderPlanAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  article?: OD3_MES_Article;
  ordernr: string;
  planEnd?: Date;
  planStart?: Date;
  setup_time: number;
  source?: OD3_Source;
  target_quantity: number;
  tenant?: OD3_Tenant;
}

export class OD3_MES_OrderPlan extends Parse.Object<OD3_MES_OrderPlanAttributes> {
  static className: string = "OD3_MES_OrderPlan";

  constructor(data?: Partial<OD3_MES_OrderPlanAttributes>) {
    super("OD3_MES_OrderPlan", data as OD3_MES_OrderPlanAttributes);
  }

  get article(): OD3_MES_Article | undefined {
    return super.get("article");
  }
  set article(value: OD3_MES_Article | undefined) {
    super.set("article", value);
  }
  get ordernr(): string {
    return super.get("ordernr");
  }
  set ordernr(value: string) {
    super.set("ordernr", value);
  }
  get planEnd(): Date | undefined {
    return super.get("planEnd");
  }
  set planEnd(value: Date | undefined) {
    super.set("planEnd", value);
  }
  get planStart(): Date | undefined {
    return super.get("planStart");
  }
  set planStart(value: Date | undefined) {
    super.set("planStart", value);
  }
  get setup_time(): number {
    return super.get("setup_time");
  }
  set setup_time(value: number) {
    super.set("setup_time", value);
  }
  get source(): OD3_Source | undefined {
    return super.get("source");
  }
  set source(value: OD3_Source | undefined) {
    super.set("source", value);
  }
  get target_quantity(): number {
    return super.get("target_quantity");
  }
  set target_quantity(value: number) {
    super.set("target_quantity", value);
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_MES_OrderPlan", OD3_MES_OrderPlan);
