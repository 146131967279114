export class AkkordBerechnungErich {
  private dichte: number;
  private durchmesser: number;
  private geschwindigkeit: number;
  private gewicht: number;
  private _produzierteLaenge: number;
  private wechselzeit: number;
  private schwierigkeit: number;
  private basis: number;
  private lauflaengeSkalierung: number;

  constructor(
    dichte: number,
    durchmesser: number,
    geschwindigkeit: number,
    gewicht: number,
    produzierteLaenge: number,
    wechselzeit: number,
    schwierigkeit: number,
    basis: number,
    llskalierung: number
  ) {
    this.dichte = dichte;
    this.durchmesser = durchmesser;
    this.geschwindigkeit = geschwindigkeit;
    this.gewicht = gewicht;
    this._produzierteLaenge = produzierteLaenge;
    this.wechselzeit = wechselzeit;
    this.schwierigkeit = schwierigkeit;
    this.basis = basis;
    this.lauflaengeSkalierung = llskalierung;
  }

  get stundenleistung(): number {
    return this.gewichtProMeter * 3600 * this.geschwindigkeit;
  }
  get gewichtProMeter(): number {
    console.log(this.durchmesser);
    console.log(this.dichte);
    console.log(Math.PI);
    const diaInMeter = this.durchmesser / 1000;
    const radius = diaInMeter / 2;
    const x = Math.pow(radius, 2) * Math.PI * this.dichte;
    console.log(x);
    return x;
  }

  get produzierteMeter(): number {
    return this._produzierteLaenge * this.lauflaengeSkalierung;
  }
  get gebindeProStunde(): number {
    return this.stundenleistung / this.gewicht;
  }
  get test(): number {
    return (this.gewicht / this.stundenleistung) * 60 + this.wechselzeit;
  }
  get theoretischeStundeLeistung(): number {
    return 60 / ((this.gewicht / this.stundenleistung) * 60 + this.wechselzeit);
  }
  get unproduktiverZeitnteilAbs(): number {
    return this.gebindeProStunde - this.theoretischeStundeLeistung;
  }
  get unproduktiverZeitnteilRel(): number {
    return this.unproduktiverZeitnteilAbs / this.gebindeProStunde;
  }
  get produktiverZeitnteilRel(): number {
    return 1 - this.unproduktiverZeitnteilRel;
  }
  get anteilSchwierigkeitsfaktor(): number {
    return (100 - this.schwierigkeit) / 100;
  }
  get produktiveStundenleistung(): number {
    return this.produktiverZeitnteilRel * this.stundenleistung;
  }
  get produktiveStundenleistungMitFaktor(): number {
    return this.produktiveStundenleistung / this.anteilSchwierigkeitsfaktor;
  }
  get preisJe100Kilo(): number {
    return (100 / this.produktiveStundenleistungMitFaktor) * this.basis;
  }
  get preisGesamt(): number {
    return this.gewichtProMeter * this.produzierteMeter * this.preisJe100Kilo;
  }
  get produziertesGewicht(): number {
    return this.gewichtProMeter * this.produzierteMeter;
  }
  get akkordZulage(): number {
    return (
      this.theoretischeStundeLeistung *
      this.gewicht *
      (this.preisJe100Kilo / 100)
    );
  }
}
