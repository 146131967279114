import Parse from "parse";

type OD3_MES_Article = Parse.Object;
type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_MES_OrderAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  article?: OD3_MES_Article;
  articlename?: string;
  articlenr?: string;
  duration: number;
  order_quantity: number;
  ordernr: string;
  setup_time: number;
  source?: OD3_Source;
  start: Date;
  status?: string;
  tag?: string;
  targettime: number;
  tenant: OD3_Tenant;
}

export class OD3_MES_Order extends Parse.Object<OD3_MES_OrderAttributes> {
  static className: string = "OD3_MES_Order";

  constructor(data?: Partial<OD3_MES_OrderAttributes>) {
    super("OD3_MES_Order", data as OD3_MES_OrderAttributes);
  }

  get article(): OD3_MES_Article | undefined {
    return super.get("article");
  }
  set article(value: OD3_MES_Article | undefined) {
    super.set("article", value);
  }
  get articlename(): string | undefined {
    return super.get("articlename");
  }
  set articlename(value: string | undefined) {
    super.set("articlename", value);
  }
  get articlenr(): string | undefined {
    return super.get("articlenr");
  }
  set articlenr(value: string | undefined) {
    super.set("articlenr", value);
  }
  get duration(): number {
    return super.get("duration");
  }
  set duration(value: number) {
    super.set("duration", value);
  }
  get order_quantity(): number {
    return super.get("order_quantity");
  }
  set order_quantity(value: number) {
    super.set("order_quantity", value);
  }
  get ordernr(): string {
    return super.get("ordernr");
  }
  set ordernr(value: string) {
    super.set("ordernr", value);
  }
  get setup_time(): number {
    return super.get("setup_time");
  }
  set setup_time(value: number) {
    super.set("setup_time", value);
  }
  get source(): OD3_Source | undefined {
    return super.get("source");
  }
  set source(value: OD3_Source | undefined) {
    super.set("source", value);
  }
  get start(): Date {
    return super.get("start");
  }
  set start(value: Date) {
    super.set("start", value);
  }
  get status(): string | undefined {
    return super.get("status");
  }
  set status(value: string | undefined) {
    super.set("status", value);
  }
  get tag(): string | undefined {
    return super.get("tag");
  }
  set tag(value: string | undefined) {
    super.set("tag", value);
  }
  get targettime(): number {
    return super.get("targettime");
  }
  set targettime(value: number) {
    super.set("targettime", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_MES_Order", OD3_MES_Order);
