import Parse from "parse";

type OD3_Maintenance_Schedule_Step = Parse.Object;
type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Maintenance_Schedule_TemplateAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  cron?: any;
  description?: string;
  enabled: boolean;
  sources: Parse.Relation<OD3_Maintenance_Schedule_Template, OD3_Source>;
  steps: Parse.Relation<OD3_Maintenance_Schedule_Template, OD3_Maintenance_Schedule_Step>;
  tenant?: OD3_Tenant;
  title?: string;
}

export class OD3_Maintenance_Schedule_Template extends Parse.Object<OD3_Maintenance_Schedule_TemplateAttributes> {
  static className: string = "OD3_Maintenance_Schedule_Template";

  constructor(data?: Partial<OD3_Maintenance_Schedule_TemplateAttributes>) {
    super("OD3_Maintenance_Schedule_Template", data as OD3_Maintenance_Schedule_TemplateAttributes);
  }

  get cron(): any | undefined {
    return super.get("cron");
  }
  set cron(value: any | undefined) {
    super.set("cron", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get enabled(): boolean {
    return super.get("enabled");
  }
  set enabled(value: boolean) {
    super.set("enabled", value);
  }
  get sources(): Parse.Relation<OD3_Maintenance_Schedule_Template, OD3_Source> {
    return super.relation("sources");
  }
  get steps(): Parse.Relation<OD3_Maintenance_Schedule_Template, OD3_Maintenance_Schedule_Step> {
    return super.relation("steps");
  }
  get tenant(): OD3_Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant | undefined) {
    super.set("tenant", value);
  }
  get title(): string | undefined {
    return super.get("title");
  }
  set title(value: string | undefined) {
    super.set("title", value);
  }
}

Parse.Object.registerSubclass("OD3_Maintenance_Schedule_Template", OD3_Maintenance_Schedule_Template);
