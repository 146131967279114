import Parse from "parse";

type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Monitoring_ParseTableSensorAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  dateColumn?: string;
  name: string;
  query: any;
  source: OD3_Source;
  tenant: OD3_Tenant;
}

export class OD3_Monitoring_ParseTableSensor extends Parse.Object<OD3_Monitoring_ParseTableSensorAttributes> {
  static className: string = "OD3_Monitoring_ParseTableSensor";

  constructor(data?: Partial<OD3_Monitoring_ParseTableSensorAttributes>) {
    super("OD3_Monitoring_ParseTableSensor", data as OD3_Monitoring_ParseTableSensorAttributes);
  }

  get dateColumn(): string | undefined {
    return super.get("dateColumn");
  }
  set dateColumn(value: string | undefined) {
    super.set("dateColumn", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get query(): any {
    return super.get("query");
  }
  set query(value: any) {
    super.set("query", value);
  }
  get source(): OD3_Source {
    return super.get("source");
  }
  set source(value: OD3_Source) {
    super.set("source", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Monitoring_ParseTableSensor", OD3_Monitoring_ParseTableSensor);
